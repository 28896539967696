const brandingConfig = {
  branding: {
    colors: {
      primary: '#18444c',
      secondary: '#005152',
      signal: '#e61c3e',
    },
    qrCode: {
      style: 'squares', // dots, fluid
      eyeRadius: 0,
      logoImage: `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_THEME_CLOUD_NAME}/image/upload/v1/themes/${process.env.NEXT_PUBLIC_THEME}/favicon/android-chrome-256x256.png`,
    },
  },
}

export default brandingConfig
